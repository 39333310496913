<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-sys__post">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getData">
        <el-form-item>
          <el-input
            v-model="dataForm.mobile"
            placeholder="购买人手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.orderId"
            placeholder="订单号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.refundMaxAmount"
            placeholder="退费最大金额"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.refundMinAmount"
            placeholder="退费最小金额"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-date-picker
            v-model="value1"
            type="daterange"
            range-separator="至"
            start-placeholder="退费开始日期"
            end-placeholder="退费结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-date-picker
            v-model="value2"
            type="daterange"
            range-separator="至"
            start-placeholder="销售开始日期"
            end-placeholder="销售结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-date-picker
            v-model="value3"
            type="daterange"
            range-separator="至"
            start-placeholder="激活开始日期"
            end-placeholder="激活结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button @click="getData" type="primary">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="exportsData">导出</el-button>
        </el-form-item>
      </el-form>

      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="orderId"
          label="订单号"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="mobile"
          label="购买人手机号"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createDate"
          label="退费时间"
          header-align="center"
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="activateDate"
          label="激活时间"
          header-align="center"
          align="center"
          width="150"
        >
        </el-table-column>

        <el-table-column
          prop="saleDate"
          label="销售时间"
          header-align="center"
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="payAmount"
          label="支付金额(元)"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="refundAmount"
          label="退款金额(元)"
          header-align="center"
          align="center"
          width="150"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-size="10"
        :total="total"
        layout="total, prev, pager, next"
        @current-change="pageCurrentChangeHandle"
      >
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
import download from './download';

const urls = {
  page: '/pay/order/cardRefundPage',
  exports: '/pay/order/cardRefundExport',
};

export default {
  data() {
    return {
      value1: [],
      value2: [],
      value3: [],
      //头部表单筛选
      dataForm: {
        refundEndDate: '',
        refundStartDate: '',
        mobile: '',
        refundMaxAmount: '',
        refundMinAmount: '',
        activeStartDate: '',
        activeEndDate: '',
        buyEndDate: '',
        buyStartDate: '',
      },
      dataListLoading: false,
      page: 1,
      total: 0,
      dataList: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let params = this.formateParams();

      this.dataListLoading = true;
      const res = await this.$http.get(urls.page, { params });
      this.dataListLoading = false;
      this.total = res.data.data.total;
      this.dataList = res.data.data.list;
    },

    async exportsData() {
      let params = this.formateParams('exports');
      download(urls.exports, params, '卡退费');
    },

    formateParams(type) {
      let {
        refundEndDate,
        refundStartDate,
        mobile,
        refundMaxAmount,
        refundMinAmount,
        buyEndDate,
        buyStartDate,
        activeStartDate,
        activeEndDate,
      } = this.dataForm;
      if (this.value1 && this.value1.length) {
        refundStartDate = this.value1[0];
        refundEndDate = this.value1[1];
      }

      if (this.value2 && this.value2.length) {
        buyStartDate = this.value2[0];
        buyEndDate = this.value2[1];
      }

      if (this.value3 && this.value3.length) {
        activeStartDate = this.value3[0];
        activeEndDate = this.value3[1];
      }
      let params = {
        refundEndDate,
        refundStartDate,
        mobile,
        refundMaxAmount,
        refundMinAmount,
        buyEndDate,
        buyStartDate,
        activeStartDate,
        activeEndDate,
      };
      if (!type) (params.limit = 10), (params.page = this.page);
      return params;
    },
    pageCurrentChangeHandle(page) {
      this.page = page;
      this.getData();
    },
  },
};
</script>
